<template>
  <div id="pondModel" ref="pondModel">
    <div
      :style="{ backgroundColor: color, top: top + 'px', left: left + 'px' }"
      class="circle"
      @mousedown="move"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["color", "top", "left"],
  methods: {
    move(e) {
      let odiv = e.target;
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {
        // console.log(this.$refs.pondModel.offsetHeight)
        //计算元素位置(需要判断临界值)
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        let { offsetHeight: pondModelHeight, offsetWidth: pondModelWidth } =
          this.$refs.pondModel;
        let { offsetHeight: sonNodeHeight, offsetWidth: sonNodeWidth } = odiv;
        // 左上角(left)
        if (left < 0) {
          left = 0;
        }
        if (top < 0) {
          top = 0;
        }
        // 左下角
        if (top > pondModelHeight - sonNodeHeight) {
          top = pondModelHeight - sonNodeHeight;
        }
        if (left > pondModelWidth - sonNodeWidth) {
          left = pondModelWidth - sonNodeWidth;
        }
        odiv.style.left = left + "px";
        odiv.style.top = top + "px";
        odiv.style.background = this.color;
        let xyz = {
          left,
          top,
        };
        this.$emit("xyz", xyz);
        odiv.style.zIndex = 999;
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
        odiv = null;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#pondModel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 196px;
}
.circle {
  cursor: move;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
</style>
