var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"footer":null,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"destroyOnClose":true,"mask":false,"width":"300px","height":"100","dialog-style":{ top: 0, margin: 0 }},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"宽度"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'width',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入宽度!',
                    } ],
                } ]),expression:"[\n                'width',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入宽度!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"number","min":"0","max":"256","step":"1","placeholder":"0~256","suffix":"px","oninput":"if(value>256)value=256; if(value< 0) value=0"},on:{"blur":function($event){return _vm.handleWidth($event)}}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"高度"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'height',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入高度!',
                    } ],
                } ]),expression:"[\n                'height',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入高度!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"number","min":"0","max":"256","step":"1","placeholder":"0~256","suffix":"px","oninput":"if(value>256)value=256; if(value< 0) value=0"},on:{"blur":function($event){return _vm.handleHeight($event)}}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"解析度"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'dpi',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入解析度!',
                    } ],
                } ]),expression:"[\n                'dpi',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入解析度!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"number","min":"0","max":"300","step":"1","placeholder":"0~300","suffix":"ppi","oninput":"if(value>300)value=300; if(value< 0) value=0"},on:{"blur":function($event){return _vm.handleDpi($event)}}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"格式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'format',
                {
                  initialValue: _vm.currency,
                } ]),expression:"[\n                'format',\n                {\n                  initialValue: currency,\n                },\n              ]"}],on:{"change":_vm.handleCurrencyChange}},[_c('a-select-option',{attrs:{"value":"png"}},[_vm._v(" PNG ")]),_c('a-select-option',{attrs:{"value":"jpg"}},[_vm._v(" JPG ")])],1)],1)],1)],1),_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('img',{staticStyle:{"margin":"0 auto 20px auto"},style:({
            width: _vm.imgWidth === '0' || !_vm.imgWidth ? '100%' : _vm.imgWidth + 'px',
            height:
              _vm.imgHeight === '0' || !_vm.imgHeight ? '100%' : _vm.imgHeight + 'px',
          }),attrs:{"src":_vm.value}})]),_c('a-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('a-col',{attrs:{"span":12}},[_c('a-button',{staticStyle:{"width":"180px","height":"48px","margin":"0 auto"},attrs:{"html-type":"submit","type":"primary","size":"large"}},[_vm._v("打印")])],1),_c('a-col',{attrs:{"span":4}},[_c('a-icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"reload","spin":_vm.spin},on:{"click":_vm.handlePreview}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }