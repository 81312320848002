<template>
  <div class="mapWall">
    <div id="map"></div>
    <createPrint
      :visible.sync="printShow"
      @handlePrintImg="handlePrintImg"
      @handlePreview="handlePreview"
      :imgLoading="imgLoading"
      @loading="loading"
      v-model="previewPrint"
    ></createPrint>
    <a-button
      @click="handelPrint"
      type="link"
      title="打印"
      size="small"
      class="polygon"
      ><a-icon type="vertical-align-bottom" style="font-size: 18px" />
    </a-button>
    <div class="inputImg">
      <img
        ref="img"
        :src="previewImg ? previewImg : require('../assets/inputImg.png')"
        style="width: 345px; height: 196px; border-radius: 10px 10px 0px 0px"
      />
      <div class="dropbox">
        <a-upload
          name="file"
          :multiple="false"
          :file-list="fileList"
          :remove="handleRemove"
          :before-upload="beforeUpload"
          accept="image/png, image/jpeg"
        >
          <a-button
            style=" display: flex;
                    border: none;
                    margin-left: 10px;
                    align-items: center;}"
            ><a-icon
              type="picture"
              style="font-size: 25px; line-height: 0"
            />导入照片</a-button
          >
        </a-upload>
      </div>
      <move-circle
        :title="msg.water"
        :left="xy.left"
        :top="xy.top"
        :color="color"
        @xyz="getXyz"
      ></move-circle>
      <move-circle
        :title="msg.label"
        :left="labelXy.left"
        :top="labelXy.top"
        :color="buildColor"
        @xyz="getBuildXyz"
      ></move-circle>
      <move-circle
        :title="msg.roadPrimary"
        :left="roadXy.left"
        :top="roadXy.top"
        :color="roadColor"
        @xyz="getRoadXyz"
      ></move-circle>
      <move-circle
        :title="msg.land"
        :left="landXy.left"
        :top="landXy.top"
        :color="landColor"
        @xyz="getLandXyz"
      ></move-circle>
      <move-circle
        :title="msg.landUse"
        :left="landUseXy.left"
        :top="landUseXy.top"
        :color="landUseColor"
        @xyz="getLandUseXyz"
      ></move-circle>
    </div>
  </div>
</template>

<script>
let map = null;
let exportControl;
import { map2DInit } from "@/util/map-utils";
import ExportControl, { imgUrl } from "@tilecloud/mbgl-export-control";
import MoveCircle from "../components/moveCircle.vue";
import createPrint from "../components/createPrint.vue";
import { getImgColor } from "../util/canvas_get_image_colors";
import { getColor } from "../util/color";
import { simpleDebounce } from "../util/util";
export default {
  name: "mapWallpaper",
  data() {
    return {
      printShow: false,
      fileList: [],
      previewImg: "",
      color: "",
      buildColor: "",
      roadColor: "",
      landColor: "",
      landUseColor: "",
      xy: {
        left: 115,
        top: 45,
      },
      labelXy: {
        left: 175,
        top: 65,
      },
      roadXy: {
        left: 275,
        top: 75,
      },
      landXy: {
        left: 105,
        top: 75,
      },
      landUseXy: {
        left: 155,
        top: 105,
      },
      msg: {
        water: "水",
        label: "文字",
        roadPrimary: "道路",
        land: "土地",
        landUse: "绿地",
      },
      previewPrint: "",
      imgLoading: false,
      customValue: {},
    };
  },
  mounted() {
    // init map
    map = map2DInit();
    map.setStyle("mapbox://styles/mapbox/streets-v11");
    map.on("moveend", () => {
      this.move();
    });
    exportControl = new ExportControl();
    // map.addControl(exportControl);
    //初始化坐标颜色
    this.getImg("init");
  },
  methods: {
    move: simpleDebounce(function () {
      //移动地图加载
      if (this.printShow) {
        this.imgLoading = true;
        this.handlePreview({
          width: this.customValue.width || 0,
          height: this.customValue.height || 0,
          dpi: this.customValue.dpi || 0,
        });
      }
    }),
    //下载自定义截图
    handlePrintImg(value) {
      this.customValue = value;
      const { width, height, dpi, format } = this.customValue;
      //自定义宽高
      exportControl.handelClick({
        map,
        zWidth: width * 10,
        zHeight: height * 10,
        zDpi: dpi * 1,
        format,
        download: "download",
      });
    },
    //获取自定义地图截图地址
    handlePreview(value) {
      this.customValue = value;
      const { width, height, dpi } = this.customValue;
      exportControl.handelClick({
        map,
        zWidth: width * 10,
        zHeight: height * 10,
        zDpi: dpi * 1,
      });
      let time = 1500;
      if (width >= 200 && height >= 200) {
        time = 3000;
      }
      setTimeout(() => {
        this.previewPrint = imgUrl();
      }, time);
    },
    //移动地图停止加载
    loading(v) {
      this.imgLoading = v;
    },
    //打开自定义宽高dpi
    handelPrint() {
      this.printShow = !this.printShow;
    },
    //删除上传附件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传附件
    beforeUpload(file) {
      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        this.$message.error("仅支持上传jpg/png格式的文件");
      } else {
        let readImg = new FileReader();
        readImg.onload = (readEvent) => {
          let base64 = readEvent.target.result;
          this.previewImg = base64;
        };
        readImg.readAsDataURL(file);
        return false;
      }
    },
    //获取坐标颜色
    getColor(a) {
      this.color = a.getColorXY(this.xy.left, this.xy.top).rgba;
      this.buildColor = a.getColorXY(this.labelXy.left, this.labelXy.top).rgba;
      this.roadColor = a.getColorXY(this.roadXy.left, this.roadXy.top).rgba;
      this.landColor = a.getColorXY(this.landXy.left, this.landXy.top).rgba;
      this.landUseColor = a.getColorXY(this.landXy.left, this.landXy.top).rgba;
    },
    //设置地图颜色
    setPP() {
      //设置水
      this.setWater();
      //设置标记
      this.setLabel();
      //设置道路
      this.setRand();
      //设置土地
      this.setLand();
      //设置绿地
      this.setLandUse();
      //建筑线
      map.setPaintProperty("building-outline", "line-color", "transparent");
      map.setPaintProperty("building", "fill-outline-color", "transparent");
      //道路名称
      map.setPaintProperty("road-label", "text-color", "transparent");
      map.setPaintProperty("road-label", "text-halo-color", "transparent");
      //车站名称/图标
      map.setPaintProperty("transit-label", "text-color", "transparent");
      map.setLayoutProperty("transit-label", "icon-image", "");
      map.setPaintProperty("transit-label", "text-halo-color", "transparent");
      //国道名称/图标
      map.setLayoutProperty("road-number-shield", "icon-image", "");
      map.setPaintProperty("road-number-shield", "text-color", "transparent");
      map.setPaintProperty(
        "road-pedestrian-polygon-pattern",
        "fill-opacity",
        0
      );
      console.log(map.getStyle());
    },
    getImg(key, m) {
      let img = this.$refs.img;
      if (key === "init" || key === "new") {
        setTimeout(() => {
          let a = new getImgColor(img);
          //初始化
          if (key === "init") {
            this.getColor(a);
            map.on("load", () => {
              this.setPP();
            });
          } else if (key === "new") {
            this.getColor(a);
            this.setPP();
          }
        }, 400);
      } else {
        let a = new getImgColor(img);
        switch (key) {
          case "water":
            this.color = a.getColorXY(m.left, m.top).rgba;
            this.setWater();
            break;
          case "label":
            this.buildColor = a.getColorXY(m.left, m.top).rgba;
            this.setLabel();
            break;
          case "road-primary":
            this.roadColor = a.getColorXY(m.left, m.top).rgba;
            this.setRand();
            break;
          case "land":
            this.landColor = a.getColorXY(m.left, m.top).rgba;
            this.setLand();
            break;
          case "land-use":
            this.landUseColor = a.getColorXY(m.left, m.top).rgba;
            this.setLandUse();
            break;
        }
      }
    },
    //设置道路颜色
    setRand() {
      let layers = map.getStyle().layers;
      for (const layer of layers) {
        if (
          (layer.id.includes("road") ||
            layer.id.includes("tunnel") ||
            layer.id.includes("bridge")) &&
          layer.type === "line"
        ) {
          map.setPaintProperty(layer.id, "line-color", this.roadColor);
        }
      }
    },
    setLabel() {
      let layers = map.getStyle().layers;
      for (const layer of layers) {
        if (layer.id.includes("label") && layer.type === "symbol") {
          if (layer.id !== "road-label") {
            map.setPaintProperty(layer.id, "text-color", this.buildColor);
            map.setPaintProperty(layer.id, "text-halo-blur", 0.1);
          }
        }
      }
    },
    setLand() {
      //加深建筑颜色
      let buildC = getColor(this.landColor);
      map.setPaintProperty("building", "fill-color", buildC);
      map.setPaintProperty("land", "background-color", this.landColor);
      map.setPaintProperty("landcover", "fill-color", this.landColor);
      // map.setPaintProperty("national-park", "fill-color", this.landColor);
    },
    setLandUse() {
      map.setPaintProperty("landuse", "fill-color", this.landUseColor);
      map.setPaintProperty(
        "road-pedestrian-polygon-fill",
        "fill-color",
        this.landUseColor
      );
    },
    setWater() {
      map.setPaintProperty("water", "fill-color", this.color);
      map.setPaintProperty("water-shadow", "fill-color", this.color);
      map.setPaintProperty("waterway", "line-color", this.color);
    },
    //获取画布坐标
    getXyz(m) {
      this.getImg("water", m);
    },
    getBuildXyz(m) {
      this.getImg("label", m);
    },
    getRoadXyz(m) {
      this.getImg("road-primary", m);
    },
    getLandXyz(m) {
      this.getImg("land", m);
    },
    getLandUseXyz(m) {
      this.getImg("land-use", m);
    },
  },
  components: {
    MoveCircle,
    createPrint,
  },
  watch: {
    //监控是否上传图片
    previewImg() {
      this.getImg("new");
      setTimeout(() => {
        if (this.printShow) {
          this.imgLoading = true;
          this.handlePreview({
            width: this.customValue.width || 0,
            height: this.customValue.height || 0,
            dpi: this.customValue.dpi || 0,
          });
        }
      }, 1000);
    },
    printShow(val) {
      if (val) {
        this.handlePreview({
          width: 0,
          height: 0,
          dpi: 0,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mapWall {
  position: relative;
  #map {
    width: 100%;
    height: calc(100vh - 64px);
    ::v-deep .mapboxgl-ctrl-top-right {
      top: 4%;
      right: 10px;
    }
    ::v-deep .mapboxgl-ctrl-attrib-inner {
      display: none;
    }
  }
  .polygon {
    width: 30px;
    padding: 0;
    background: #fff;
    border-color: #fff;
    color: rgba(#000, 0.65);
    position: absolute;
    top: 50px;
    right: 1.5%;
  }
  .inputImg {
    user-select: none;
    position: absolute;
    left: 2%;
    top: 70%;
    width: 345px;
    border-radius: 10px;
    .dropbox {
      width: 100%;
      border-radius: 0px 0px 10px 10px;
      background: #fff;
    }
  }
}
</style>
