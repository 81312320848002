export function rgbaToTgb(color, level) {
  let rgbaAttr = color.match(/[\d.]+/g);
  if (rgbaAttr.length >= 3) {
    let r, g, b;
    r = Math.floor(rgbaAttr[0] * (1 - level));
    g = Math.floor(rgbaAttr[1] * (1 - level));
    b = Math.floor(rgbaAttr[2] * (1 - level));
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  return "";
}

export function getColor(color) {
  return rgbaToTgb(color, 0.1);
}
