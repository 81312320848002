<template>
  <div>
    <a-modal
      :footer="null"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :destroyOnClose="true"
      :mask="false"
      width="300px"
      height="100"
      :dialog-style="{ top: 0, margin: 0 }"
    >
      <a-form @submit="handleSubmit" :form="form">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="宽度">
              <a-input
                type="number"
                min="0"
                max="256"
                step="1"
                placeholder="0~256"
                v-decorator="[
                  'width',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入宽度!',
                      },
                    ],
                  },
                ]"
                suffix="px"
                oninput="if(value>256)value=256; if(value< 0) value=0"
                @blur="handleWidth($event)"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="高度">
              <a-input
                type="number"
                min="0"
                max="256"
                step="1"
                placeholder="0~256"
                v-decorator="[
                  'height',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入高度!',
                      },
                    ],
                  },
                ]"
                suffix="px"
                oninput="if(value>256)value=256; if(value< 0) value=0"
                @blur="handleHeight($event)"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="解析度">
              <a-input
                type="number"
                min="0"
                max="300"
                step="1"
                placeholder="0~300"
                v-decorator="[
                  'dpi',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入解析度!',
                      },
                    ],
                  },
                ]"
                suffix="ppi"
                oninput="if(value>300)value=300; if(value< 0) value=0"
                @blur="handleDpi($event)"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="格式">
              <a-select
                v-decorator="[
                  'format',
                  {
                    initialValue: currency,
                  },
                ]"
                @change="handleCurrencyChange"
              >
                <a-select-option value="png"> PNG </a-select-option>
                <a-select-option value="jpg"> JPG </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between">
          <img
            :src="value"
            :style="{
              width: imgWidth === '0' || !imgWidth ? '100%' : imgWidth + 'px',
              height:
                imgHeight === '0' || !imgHeight ? '100%' : imgHeight + 'px',
            }"
            style="margin: 0 auto 20px auto"
          />
        </a-row>
        <a-row type="flex" justify="space-between" align="middle">
          <a-col :span="12">
            <a-button
              html-type="submit"
              type="primary"
              size="large"
              style="width: 180px; height: 48px; margin: 0 auto"
              >打印</a-button
            >
          </a-col>
          <a-col :span="4">
            <a-icon
              type="reload"
              style="font-size: 20px"
              :spin="spin"
              @click="handlePreview"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "createPrint",
  props: ["visible", "value", "imgLoading"],
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, {
        onValuesChange: this.handleValuesChange,
      }),
      currency: "png",
      previewImg: "",
      imgWidth: "",
      imgHeight: "",
      imgDpi: "",
      spin: false,
    };
  },
  methods: {
    handleCurrencyChange(currency) {
      this.currency = currency;
    },
    handleCancel() {
      this.imgWidth = "";
      this.imgHeight = "";
      this.imgDpi = "";
      this.$emit("update:visible", false);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit("update:visible", false);
          this.$emit("handlePrintImg", values);
          this.imgWidth = "";
          this.imgHeight = "";
          this.imgDpi = "";
        }
      });
    },
    handleWidth(value) {
      if (this.imgWidth !== value.target._value) {
        this.imgWidth = value.target._value;
        this.handlePreview();
      }
    },
    handleHeight(value) {
      if (this.imgHeight !== value.target._value) {
        this.imgHeight = value.target._value;
        this.handlePreview();
      }
    },
    handleDpi(value) {
      if (this.imgDpi !== value.target._value) {
        this.imgDpi = value.target._value;
        this.handlePreview();
      }
    },
    handlePreview() {
      if (this.imgWidth && this.imgHeight && this.imgDpi) {
        this.form.validateFields((err) => {
          if (!err) {
            let value = {
              width: this.imgWidth,
              height: this.imgHeight,
              dpi: this.imgDpi,
            };
            this.spin = true;
            this.$emit("handlePreview", value);
            setTimeout(() => {
              if (this.spin) {
                this.spin = false;
                this.$message.error("请重新刷新");
              }
            }, 7000);
          }
        });
      }
    },
  },
  watch: {
    value(val) {
      this.previewImg = val;
      this.spin = false;
      if (this.imgLoading) {
        this.$emit("loading", false);
      }
    },
    visible(val) {
      if (val && !this.previewImg) this.spin = true;
    },
    imgLoading(v) {
      if (v) {
        this.spin = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ant-modal-wrap {
  top: 105px;
  left: 2%;
  margin: 0px;
  width: 300px;
  height: 330px;
  overflow: visible;
}
::v-deep .ant-modal {
  transform-origin: 0 0 !important;
}
</style>
